import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    num:sessionStorage.getItem('num')?sessionStorage.getItem('num'):'1'
  },
  mutations: {
    //改变数字
    changeNum(state,val) {
      // console.log(state,val);
      state.num = val
      window.sessionStorage.setItem('num',val)
    }
  },
  actions: {},
  modules: {},
});
