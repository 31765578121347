import Vue from "vue";
import VueRouter from "vue-router";
// 中文
import Home from "../views/Home.vue";
const Index = () => import('../views/Index/Index.vue')
const Product = () => import('../views/Product/Product.vue')
const News = () => import('../views/News/News.vue')
const NewsDetail = () => import('../views/News/NewsDetail.vue')
const About = () => import('../views/About/About.vue')
const Programme = () => import('../views/Product/Programme.vue')
const Industry = () => import('../views/Industry/Industry.vue')
const Server = () => import('../views/Server/Server.vue')

// 英文
const HomeEn = () => import('../view_en/HomeEn/HomeEn.vue')
const IndexEn = () => import('../view_en/IndexEn/IndexEn.vue')
const ProductEn = () => import('../view_en/ProductEn/ProductEn.vue')
const IndustryEn = () => import('../view_en/IndustryEn/IndustryEn.vue')
const ServerEn = () => import('../view_en/ServerEn/ServerEn.vue')
const ProgrammeEn = () => import('../view_en/ProgrammeEn/ProgrammeEn.vue')
const NewsDetailEn = () => import('../view_en/NewsEn/NewsDetailEn.vue')
const NewsEn = () => import('../view_en/NewsEn/NewsEn.vue')

const AboutEn = () => import('../view_en/AboutEn/AboutEn.vue')
Vue.use(VueRouter);

const routes = [
  // 中文
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: '/index',
    children:[
      {
        path: '/index',
        name: 'index',
        component: Index,
        meta: { title: '首页' }
      },
      {
        path: '/product',
        name: 'product',
        component: Product,
        meta: { title: '产品及方案' }
      },
      {
        path: '/industry',
        name: 'industry',
        component: Industry,
        meta: { title: '行业方案' }
      },
      {
        path: '/server',
        name: 'server',
        component: Server,
        meta: { title: '服务支持' }
      },
      {
        path: '/programme',
        name: 'programme',
        component: Programme,
        meta: { title: '产品' }
      },
      {
        path: '/news',
        name: 'news',
        component: News,
        meta: { title: '新闻中心' }
      },
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: NewsDetail,
        meta: { title: '新闻详情' }
      },
      {
        path: '/about',
        name: 'about',
        component: About,
        meta: { title: '关于我们' }
      },
    ]
  },
  // 英文
  {
    path: "/",
    name: "HomeEn",
    component: HomeEn,
    redirect: '/indexEn',
    children:[
      {
        path: '/indexEn',
        name: 'indexEn',
        component: IndexEn,
        meta: { title: 'HOME' }
      },
      {
        path: '/productEn',
        name: 'productEn',
        component: ProductEn,
        meta: { title: 'Product ' }
      },
      {
        path: '/industryEn',
        name: 'industryEn',
        component: IndustryEn,
        meta: { title: 'Industry' }
      },
      {
        path: '/serverEn',
        name: 'serverEn',
        component: ServerEn,
        meta: { title: 'Support' }
      },
      {
        path: '/programmeEn',
        name: 'programmeEn',
        component: ProgrammeEn,
        meta: { title: 'Solutions' }
      },
      {
        path: '/newsEn',
        name: 'newsEn',
        component: NewsEn,
        meta: { title: 'InfoCenter' }
      },
      {
        path: '/newsDetailEn',
        name: 'newsDetailEn',
        component: NewsDetailEn,
        meta: { title: 'Detail' }
      },
      {
        path: '/aboutEn',
        name: 'aboutEn',
        component: AboutEn,
        meta: { title: 'About' }
      },
    ]
  },
];

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
});

export default router;
