<template>
  <div class="home">
    <!-- PC公共头部 -->
    <div class="home_top hidden-xs-only">
      <div class="tarbar">
        <div class="section">
          <div class="tarbar_left">
            <img src="../assets/images/index/logo.png" alt="" />
          </div>
          <div class="tarbar_right">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="1">首页</el-menu-item>
              <el-submenu index="2">
                <template slot="title">
                  <div :class="{ selected: activeIndex.toString().slice(0,1) == 2 }" class="clickTitle" @click="go()">产品及方案</div>
                </template>
                <el-menu-item class="newBack" :index="(22)+'-'+(item.id)" v-for="(item) in routeList[1].children" :key="item.id">{{item.title}}</el-menu-item>
              </el-submenu>
              <el-submenu index="3">
                <template slot="title">
                  <div :class="{ selected: activeIndex.toString().slice(0,1) == 3 }" class="clickTitle" @click="goIndustry()">行业方案</div>
                </template>
                <el-menu-item class="newBack" :index="(33)+'-'+(item.id)" v-for="(item) in routeList[2].children" :key="item.id">{{item.title}}</el-menu-item>
              </el-submenu>
              <el-submenu index="4">
                <template slot="title">
                  <div :class="{ selected: activeIndex.toString().slice(0,1) == 4 }" class="clickTitle" @click="goServer()">服务支持</div>
                </template>
                <el-menu-item class="newBack" :index="(44)+'-'+(item.id)" v-for="(item) in routeList[3].children" :key="item.id">{{item.title}}</el-menu-item>
              </el-submenu>
              <el-menu-item index="5">新闻中心</el-menu-item>
              <el-menu-item index="6">关于我们</el-menu-item>
            </el-menu>
            <div class="change" @click="changeLanguage(1)">中文/EN</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 手机版头部 -->
    <div class="mobile_top hidden-sm-and-up">
      <div class="mobile_top_title">
        <div class="img">
          <img v-if="path.indexOf('index')!=-1" class="logo" src="../assets/images/index/logo.png" alt="" />
          <img v-else class="backButton" @click="backButton" src="../assets/images/index/back.png" alt="" />
        </div>
        <div class="routePath">{{ $route.meta.title }}</div>
        <img class="more" src="../assets/images/mobile/index/more.png" alt="" />
      </div>
      <!-- 导航栏 -->
      <div class="header_mobile_content" style="display: none">
        <div class="mobile_content_top">
            <span></span>
        </div>
        <div class="mobile_content_middle">
            <el-collapse accordion>
                <template  v-for="(item,index) in routeList">
                  <el-collapse-item :key="index" :title="item.title" :name="index" v-if="item.children ? (item.children.length > 1 ? true : false) : false">
                    <div v-for="(item2,index2) in item.children" :key="index2" @click="childrenGopage(item,item2,index)">
                      {{item2.title}}
                    </div>
                  </el-collapse-item>
                  <el-collapse-item :key="index" :title="item.title" disabled :name="index" v-else @click.native="mobileGoPage(item,item)">
                    <div  @click="mobileGoPage()">aa</div>
                  </el-collapse-item>
                </template>
            </el-collapse>
            <div class="change" @click="changeLanguage(1)">中文/EN</div>
        </div>
      </div>
    </div>
    <div class="content">
            <router-view :key="$route.fullPath"></router-view>
    </div>
    <!-- PC公共底部 -->
    <div class="home_bottom hidden-xs-only">
      <div class="section">
        <div class="section_top">
          <div class="left">
            <img class="one" src="../assets/images/index/bottom_top.png" alt="" />
            <div class="two">联系我们</div>
            <div class="two">TEL：021-28903367</div>
            <div class="three">info@networkjoint.com</div>
            <div>上海市闵行区吴中路1189号德必易园</div>
          </div>
          <div class="middle">
            <div class="one">智慧广域网</div>
            <div class="two">高速云联</div>
            <div class="two">虚拟专用网</div>
            <div class="three">边缘数据中心</div>
            <!-- <div>全球互聯網接入</div> -->
          </div>
          <div class="right">
            <img src="../assets/images/index/qrcodes.png" alt="" />
          </div>
        </div>
        <div class="section_bottom">
          <div class="texts">Copyright © networkjoint.com 2022.AllRights Reserved <span><a href="https://beian.miit.gov.cn" target="_blank">沪ICP备17048297号-2</a></span></div>
        </div>
      </div>
      
    </div>
    <!-- 手机端底部 -->
    <div class="mobile_bottom hidden-sm-and-up">
      <div class="mobile_bottom_top">
        <div class="one">智慧广域网</div>
        <div class="two">高速云联</div>
        <div class="two">虚拟专用网</div>
        <div class="three">边缘数据中心</div>
        <!-- <div>全球互聯網接入</div> -->
      </div>
      <div class="mobile_bottom_bottom">
        <div class="left">
          <div class="two">联系我们</div>
          <div class="two">TEL：021-28903367</div>
          <div class="three">info@networkjoint.com</div>
          <div>上海市闵行区吴中路1189号德必易园</div>
        </div>
        <div class="right">
          <img src="../assets/images/index/qrcodes.png" alt="" />
        </div>
        
      </div>
      <div class="section_bottom">
        <div class="texts">Copyright © networkjoint.com 2022.AllRights Reserved</div>
        <div class="filings"><span><a href="https://beian.miit.gov.cn" target="_blank">沪ICP备17048297号-2</a></span></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
export default {
  computed: {
    myValue() {
      return this.$store.state.num;
    },
  },
  watch: {
    myValue: function (val) {
      this.activeIndex = val;
    },
  },
  data() {
    return {
      url:process.env.VUE_APP_WEB_URL,
      type:1,
      activeIndex:this.$store.state.num,
      activeName:1,
      routeList: [
        { path: "/index", value: "0", title: "首页" },
        { path: "/product", value: "0", children:[],title: "产品及方案" },
        { path: "/industry", value: "0",children:[{title:'金融行业',id:1},{title:'制造业',id:2},{title:'零售业',id:3}], title: "行业方案" },
        { path: "/server", value: "0",children:[{title:'下载中心',id:1},{title:'客户服务',id:2},{title:'常见问题',id:3}], title: "服务支持" },
        { path: "/news", value: "0", children:[],title: "新闻中心" },
        { path: "/about", value: "0",children:[], title: "关于我们" },
      ],
      path:this.$route.path,
      ids:[]
    };
  },
  methods: {
    // 产品分类
    getCategoryTreeList() {
      axios({
        method: "post",
        url: this.url+`/category/treeList/`+this.type,
      }).then(res => {
        let list = res.data
        let listOne =[]
        list.forEach(item => {
          listOne.push({
            id:item.value,
            title:item.name
          })
          this.ids.push(item.value)
        });
        this.routeList.forEach(item=>{
          if(item.path=='/product') {
            listOne.forEach(items=>{
              item.children.push(items)
            })
          }
        })
      })
    },
    handleSelect(e) {
      let numthree = this.ids[0]
      let numFour = this.ids[1]
      let numOne = '22' +'-'+ this.ids[0]
      let numTwo = '22' +'-'+ this.ids[1]
      let num = e
      if(num == 1) {
        this.$router.push('/index')
      } else if(num.indexOf(numOne)!=-1) {
        this.$router.push({path:'/programme',query:{id:numthree}})
      } else if(num.indexOf(numTwo)!=-1) {
        this.$router.push({path:'/programme',query:{id:numFour}})
      } else if(num.indexOf('33-1')!=-1) {
        this.$router.push({path:'/industry',query:{id:1}})
      } else if(num.indexOf('33-2')!=-1) {
        this.$router.push({path:'/industry',query:{id:2}})
      } else if(num.indexOf('33-3')!=-1) {
        this.$router.push({path:'/industry',query:{id:3}})
      } else if(num.indexOf('44-1')!=-1) {
        this.$router.push({path:'/server',query:{id:1}})
      } else if(num.indexOf('44-2')!=-1) {
        this.$router.push({path:'/server',query:{id:2}})
      } else if(num.indexOf('44-3')!=-1) {
        this.$router.push({path:'/server',query:{id:3}})
      } else if(num==5) {
        this.$router.push('/news')
      } else if(num==6) {
        this.$router.push('/about')
      }
      this.activeIndex = num
      this.$store.commit("changeNum", num);
    },
    // 手机
    initDropDown() {
        $('.mobile_top_title .more').click(function() {
            $('.header_mobile_content').animate({width:'toggle'},300);
        })
        $('.mobile_content_top span').click(function() {
            $('.header_mobile_content').animate({width:'toggle'},300);
        })
    },
    // 没有子分类
    mobileGoPage(item){
      this.$router.push(item.path)
      this.path = item.path
      $('.header_mobile_content').hide()
    },
    // 有展开的事件  有子分类
    childrenGopage(item,item2,index){
      let id = item2.id
      if(item.path=='/product') {
        this.$router.push({path:'/programme',query:{id:id}})
      } else {
        this.$router.push({path:item.path,query:{id:id}})
      }
      this.path = item.path
      $('.header_mobile_content').hide()
    },
    // 去英文版
    changeLanguage(num) {
      let path = this.$route.path
      let id = this.$route.query.id
      // console.log(path,id);
      this.$router.push({path:path+'En',query:{id:id}})
      // this.$router.push('/indexEn')
      // this.$store.commit("changeNum", num);
    },
    go(){
      let num = '22-1'
      this.activeIndex = num
      this.$store.commit("changeNum", num);
      this.$router.push('/product')
    },
    goIndustry() {
      let num = '33-1'
      this.activeIndex = num
      this.$store.commit("changeNum", num);
      this.$router.push({path:'/industry',query:{id:1}})
    },
    goServer() {
      let num = '44-1'
      this.activeIndex = num
      this.$store.commit("changeNum", num);
      this.$router.push({path:'/server',query:{id:1}})
    },
    backButton() {
      this.$router.back()
    },
  },
  mounted() {
    this.initDropDown()
    // 产品的分类
    this.getCategoryTreeList()
  },
};
</script>
<style  lang="scss">
.home {
  position: relative;
  .home_top {
    width: 100%;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    box-shadow: 1px 1px 1px 1px #e4e4e4;
    .tarbar {
      height: 90px;
      .section {
        display: flex;
        justify-content: space-between;
        .tarbar_left {
          img {
            margin-top: 22px;
            width: 265px;
            height: 42px;
          }
        }
        .tarbar_right {
          display: flex;
          .el-menu{
            border: none;
            height: 90px;
            .el-menu-item {
              height: 92px;
              line-height: 92px;
              font-size: 16px;
              width: 110px;
              text-align: center;
              color: #303133;
            }
            .el-submenu {
              .el-submenu__title {
                  padding: 0;
                  height: 92px;
                  line-height: 92px;
                  font-size: 16px;
                  color: #303133;
                  .el-submenu__icon-arrow {
                    display: none;
                  }
                  .clickTitle {
                    padding: 0 20px;
                  }
                  .selected {
                     background: linear-gradient(to top right, #25aae1, #197dbe);
                     color: #fff;
                  }
              }
            }
            .is-active {
                background: linear-gradient(to top right, #25aae1, #197dbe);
                color: #fff;
                border-bottom: none;
                .el-submenu__title {
                  height: 92px;
                  line-height: 92px;
                  font-size: 16px;
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  color: #fff;
                  border-bottom: none;
                }
            }
            
          }
          .change {
            text-align: center;
            height: 92px;
            line-height: 92px;
            width: 110px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .home_bottom {
    background: url("../assets/images/index/bottom.png") no-repeat center center;
    background-size: cover;
    // height: 230px;
    .section {
      height: 250px;
      .section_top {
        height: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 14px;
        opacity: 0.9;
        .left {
          .one {
            margin-bottom: 13px;
          }
          .two {
            margin-bottom: 9px;
          }
          .three {
            margin-bottom: 13px;
          }
        }
        .middle {
          height: 60%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .right {
          img {
            width: 135px;
          }
        }
      }
      .section_bottom {
        text-align: center;
        .texts {
          font-size: 18px;
          color: #fff;
          span {
            a {
              color: #fff;
              cursor: pointer;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
      text-align: center!important;
  }
}
@media (max-width: 768px) {
  .home {
    .mobile_top {
      padding: 0 0.3rem 0 .2rem;
      position: relative;
      .mobile_top_title {
        height: 1.1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img {
          display: flex;
          align-items: center;
          width: 0.4rem;
          .logo {
            height: 0.3rem;
            vertical-align: bottom;
            margin-top: 0.1rem;
          }
          .backButton {
            height: 0.5rem;
          }
        }
        .routePath {
          text-align: center;
          font-size: 0.4rem;
        }
        .more {
          width: 0.4rem;
          vertical-align: bottom;
        }
      }
      .header_mobile_content {
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(11, 11, 11, 0.9);
            width: 100vw;
            height: 100vh;
            z-index: 99999;
            .mobile_content_top {
                height: 1.2rem;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                width: 100%;
                span {
                    padding-right: 1rem;
                    display: inline-block;
                    width: 0.4rem;
                    height: 0.4rem;
                    background: url('../assets/images/mobile/index/cancle.png') center center no-repeat;
                    transform: rotate(180deg);
                }
            }
            .mobile_content_middle {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 100%;
                // margin-top: 1rem;
                .el-collapse {
                  border: none;
                    .el-collapse-item {
                        width: 100%;
                        border: none;
                        .el-collapse-item__header{
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            border: none;
                            color: #fff;
                            font-size: .4rem;
                            background-color: #1e1e1e;
                            // background: linear-gradient(to top right, #25aae1, #197dbe);
                            .el-collapse-item__arrow  {
                                display: none;
                            }
                        }
                    }
                    .is-active {
                      background: linear-gradient(to top right, #25aae1, #197dbe);
                    }
                    .el-collapse-item__wrap{
                            border: none;
                            .el-collapse-item__content {
                                text-align: center;
                                padding: 0;
                                border-bottom: none;
                                div {
                                    border-bottom: 1px solid #dcdcdc!important;
                                    font-size: .32rem;
                                    line-height: 1rem;
                                    // color: #197dbe;
                                    cursor: pointer;
                                }
                                .selected {
                                    color: #197dbe;
                                }
                            }
                    }
                }
                .change {
                  text-align: center;
                  color: #fff;
                  line-height: 1rem;
                  font-size: .4rem;
                }
            }
      }
    }
    .mobile_bottom {
      background: url("../assets/images/mobile/index/index_2.png") no-repeat
      center center;
      background-size: cover;
      height: 4.8rem;
      color: #fff;
      font-size: 0.25rem;
      padding: 0 0.3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .mobile_bottom_top {
        position: relative;
        padding: 0.1rem 0;
        div {
          line-height: 0.4rem;
        }
      }
      .mobile_bottom_top::after {
        content: "";
        position: absolute;
        width: 200%;
        height: 1px;
        background-color: aqua;
        transform: scale(0.5);
        bottom: 0;
        left: -50%;
      }
      .mobile_bottom_bottom {
        padding: 0.1rem 0;
        display: flex;
        justify-content: space-between;
        .left {
          div {
            line-height: 0.4rem;
          }
        }
        .right {
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
      .section_bottom{
        .filings{
          span {
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
